<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { authComputed } from "@/state/helpers";
import store from "@/state/store";
import JsonExcel from "vue-json-excel";
export default {
  beforeRouteEnter(to, from, next) {
    const userType = store.getters["auth/getUser"].user_type;
    const permissions = store.getters["auth/permissions"];
    if (
      (userType === 1 || userType === 3) &&
      permissions.includes("TIMESHEET")
    ) {
      next();
    } else {
      alert("You are not allowed to access this page. You will be redirected.");
      next("/");
    }
  },
  page: {
    title: "Timesheets",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    ...authComputed,
  },
  components: {
    Layout,
    PageHeader,
    JsonExcel
  },
  mounted() {
    this.refreshTable();
  },
  methods: {
    refreshTable() {
      this.period = this.$route.params.period;
      this.period = this.period.split("-");
      this.location = this.$route.params.locationID;
      this.month = this.period[0];
      this.year = this.period[1];
      this.selectedPeriod = new Date(`${this.month} 1 ${this.year}`);
      let data = {
        month: parseFloat(this.period[0]),
        year: parseFloat(this.period[1]),
        location: parseFloat(this.location),
      };
      const url = `${this.ROUTES.timeAllocation}/timesheet-application-tracking-report`;
      this.apiPost(url, data, "Generate Timesheet Tracker Report").then(
        (res) => {
          let count = 0;
          const { data } = res;
          //console.log({ data });
          //this.filtered = data.allocations;
          this.excel_fields.forEach((field) => {
            let key = field.key;
            //console.log(field);
            if (key === "sn") {
              this.jsonFields["S/N"] = key;
            } else if (key === "t7") {
              this.jsonFields["T7"] = key;
            } else if (key === "d7") {
              this.jsonFields["D7"] = key;
            } else if (key === "employee") {
              this.jsonFields["Name"] = key;
            } else if (key === "position") {
              this.jsonFields["Position"] = key;
            } else if (key === "location") {
              this.jsonFields["Location"] = key;
            } else if (key === "sector") {
              this.jsonFields["Sector"] = key;
              //console.log(`Key ${key} - u`)
            } else if (key === "contract_date") {
              this.jsonFields["Contract Start Date"] = key;
            } else if (key === "contract_end_date") {
              this.jsonFields["Contract End Date"] = key;
            } else if (key === "status") {
              this.jsonFields["Status"] = key;
            } else if (key === "payroll_month") {
              this.jsonFields["Payroll Month"] = key;
            } else if (key === "payroll_year") {
              this.jsonFields["Payroll Year"] = key;
            }
          });
          this.loc = data.loc;
          const employees = data.salaryEmployees;
          const timesheetEmpIds = data.timesheetEmpIds;
          //console.log( employees);
          employees.map( (time)=>{
            //console.log(time.employee)
            let status;
            let ex_status;
            let ref;
              const x = timesheetEmpIds.find(timesheetEmpId => {
                return timesheetEmpId.empId === time.employee.emp_id
              });
              if((x === undefined) || (x === null)){
                status = 2; //not submitted
                ex_status = "Not submitted"
                ref = null;
              }else{
                if(parseInt(x.status) === 0){
                  ex_status = 'Pending approval'
                }else if(parseInt(x.status) === 1){
                  ex_status = 'Approved';
                }
                status = x.status;
                ref = x.ref;
              }

            const empData = {
              sn: ++count,
              ref_no: ref || null,
              payroll_month: new Date(time.salary_paymonth).toLocaleString('en-US', {month: 'long'}) || 10,
              payroll_year: time.salary_payyear || 2023,
              status: ex_status,
              t_status: status,
              employee: `${time.employee.emp_first_name} ${time.employee.emp_last_name}`,
              d7: time.employee.emp_d7 || '',
              location:
                parseInt(this.location) === 0
                  ? `${time.location?.location_name || ""}  (${
                    time.location?.l_t6_code || ""
                  })`
                  : `All Locations`,
              t7: time.employee.emp_unique_id,
              sector: `${time.employee.sector?.department_name || ""}  (${
                time.employee.sector?.d_t3_code || ""
              })`,
              position: time.jobrole?.job_role,
              contract_date: new Date(
                time.employee.emp_hire_date
              ).toLocaleDateString(),
              contract_end_date: new Date(
                time.employee.emp_contract_end_date
              ).toLocaleDateString(),
            };
            this.timeAllocations.push(empData);
            this.filtered.push(empData);
          });
          this.totalRows = this.timeAllocations.length;
        }
      );
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    selectRow(row) {
      row = row[0];
      //console.log({ row });
      const month = `${row.payroll_month}`;
      const year = `${row.payroll_year}`;
      const empId = row.employee.emp_id;
      const refNo = row.ref_no;
      this.$router.push({
        name: "manage-time-sheet-details",
        params: { month, year, empId, refNo },
      });
    },
  },
  data() {
    return {
      title: "Timesheet Tracking Report",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Timesheet Tracking Report",
          active: true,
        },
      ],
      timeAllocations: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "sn",
      sortDesc: false,
      loc: null,
      location: null,
      month: null,
      year: null,
      selectedPeriod: null,
      jsonFields: {},
      filtered: [],
      excel_fields: [
        { key: "sn", label: "S/n", sortable: true },
        { key: "t7", label: "T7", sortable: true },
        { key: "d7", label: "D7", sortable: true },
        { key: "employee", label: "Name", sortable: true },
        { key: "position", label: "Position", sortable: true },
        { key: "location", label: "Location", sortable: true },
        { key: "sector", label: "Sector", sortable: true },
        { key: "contract_date", label: "Contract Start Date", sortable: true },
        { key: "contract_end_date", label: "Contract End Date", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "payroll_month", label: "Payroll Month", sortable: true },
        { key: "payroll_year", label: "Payroll Year", sortable: true },
      ],
      fields: [
        { key: "sn", label: "S/n", sortable: true },
        { key: "t7", label: "T7", sortable: true },
        { key: "d7", label: "D7", sortable: true },
        {
          key: "employee",
          label: "Name",
          sortable: true,
        },

        {
          key: "position",
          label: "Position",
          sortable: true,
        },
        { key: "location", label: "Location", sortable: true },
        {
          key: "sector",
          label: "Sector(T3)",
          sortable: false,
        },
        {
          key: "contract_date",
          label: "Contract Start Date",
          sortable: false,
        },
        {
          key: "contract_end_date",
          label: "Contract End Date",
          sortable: false,
        },
        {
          key: "t_status",
          label: "Status",
          sortable: true,
        },
        {
          key: "payroll_month",
          label: "Payroll Month",
          sortable: true,
        },
        {
          key: "payroll_year",
          label: "Payroll Year",
          sortable: true,
        },
      ],
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <scale-loader v-if="apiBusy" />
    <div v-else class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-12">
                <h4>
                  Timesheet Tracking Report for
                  <span class="text-success">{{
                    parseInt(location) === 0
                      ? "All Locations"
                      : loc.location_name
                  }}</span
                  >({{
                    selectedPeriod.toLocaleDateString("default", {
                      month: "long",
                    })
                  }},{{ selectedPeriod.getFullYear() }})
                </h4>
                <button class="font-size-12 btn btn-success float-right">
                  <JsonExcel
                    style="cursor: pointer"
                    :data="filtered"
                    :fields="jsonFields"
                    :name="`Timesheet_Report(${month}_${new Date().toDateString()}).xls`"
                  >
                    Export to Excel
                  </JsonExcel>
                </button>
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                ref="donor-table"
                bordered
                selectable
                hover
                :items="timeAllocations"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
                select-mode="single"
                @row-selected="selectRow"
              >
                <template #cell(employee)="row">
                  <p class="mb-0">
                    {{ row.value }}
                    <!--                    {{ row.value.emp_first_name }}-->
                    <!--                    {{ row.value.emp_last_name }}-->
                  </p>
                </template>
                <template #cell(t_status)="row">
                  <span
                    v-if="row.value === 0"
                    class="badge badge-pill badge-warning"
                  >
                    Pending Approval
                  </span>
                  <span
                    v-else-if="row.value === 1"
                    class="badge badge-pill badge-success"
                  >
                    Approved
                  </span>
                  <span
                    v-else-if="row.value === 2"
                    class="badge badge-pill badge-danger"
                  >
                    Not Submitted
                  </span>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
